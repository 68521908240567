<template>
  <router-view></router-view>
</template>

<script>
/**
 * Esta pantalla sirve como base de entrada de otras vistas,
 * usadas especificamente para la recuperación de datos.
 *
 * Sirve para vistas aninadas, si posteriormente se deseara aplicar
 * lógica para aquellas vistas podría hacerse desde este punto.
 */
export default {
  name: 'RecoverView'
}
</script>
